// 头部登陆注册导航栏
<template>
    <div class="header_wrap">
        <div class="header">
            <div class="header_left">
                <span class="hello">{{ L["您好，欢迎来到"] }}{{ platform == 1 ? "大石工采" : "大石集采" }}</span>
                <template v-if="loginFlag">
                    <span class="register h1" @click="goToByPush('/member/index')">{{
                        memberInfo.memberMobile || memberInfo.memberMobile
                    }}</span>
                    <span class="register h1 login_out" @click="loginOut()">[{{ L["退出"] }}]</span>
                </template>

                <template v-if="!loginFlag">
                    <span class="register h1" @click="goToByPush('/login')">{{ L["登录"] }}</span>
                    <span class="register h1" @click="goToByPush('/register')">{{ L["注册"] }}</span>
                    <span class="vipPrice" v-if="vipPrice">
                        <img src="@/assets/goodsDetailsTopNavVip.png" alt="" />
                        <span style="margin: 0 14px 0 6px">注册企业会员，立享会员低价!</span>
                        <img @click="vipPrice = false" src="@/assets/goodsDetailsTopNavClose.png" alt="" />
                    </span>
                </template>
            </div>
            <div class="header_right">
                <ul>
                    <li @click="getEnterprise" v-if="memberInfo.memberUserType == 1">
                        <div class="li_item">企业切换</div>
                    </li>
                    <li @click="qiyecaigouShow = true">
                        <div class="li_item">
                            {{ L["企业采购"] }}
                        </div>
                    </li>
                    <li @click="goToByPush('/index', {})">
                        <div class="li_item">{{ L["商城首页"] }}</div>
                    </li>
                    <li>
                        <div class="has_more li_item">
                            {{ L["我的订单"] }}
                            <div class="li_item_more">
                                <a
                                    href="javascript:void(0)"
                                    class="li_item_more_item"
                                    @click="goToByPush('/member/order/list', { orderState: 10 })"
                                >
                                    {{ L["待支付订单"] }}
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    class="li_item_more_item"
                                    @click="goToByPush('/member/order/list', { orderState: 20 })"
                                >
                                    待发货订单
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    class="li_item_more_item"
                                    @click="goToByPush('/member/order/list', { orderState: 30 })"
                                >
                                    {{ L["待收货订单"] }}
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    class="li_item_more_item"
                                    @click="goToByPush('/member/order/list', { orderState: 40 })"
                                >
                                    已完成订单
                                </a>
                                <!-- <a href="javascript:void(0)" class="li_item_more_item"
                  @click="goToByPush('/member/order/evaluation')">{{L['待评价订单']}}</a> -->
                            </div>
                        </div>
                    </li>
                    <li @click="goToByPush('/member/index')">
                        <div class="li_item">{{ L["个人中心"] }}</div>
                    </li>
                    <!-- <li>
            <div class="has_more li_item">
              {{L['我的收藏']}}
              <div class="li_item_more">
                <a href="javascript:void(0)" class="li_item_more_item"
                  @click="goToByPush('/member/collect')">{{L['商品收藏']}}</a>
                <a href="javascript:void(0)" class="li_item_more_item"
                  @click="goToByPush('/member/collect',{type:'store'})">{{L['店铺收藏']}}</a>
                <a href="javascript:void(0)" class="li_item_more_item"
                  @click="goToByPush('/member/footprint')">{{L['我的足迹']}}</a>
              </div>
            </div>
          </li> -->
                    <li>
                        <div class="has_more li_item">
                            {{ L["我的账户"] }}
                            <div class="li_item_more">
                                <!-- <a href="javascript:void(0)" class="li_item_more_item" @click="goToByPush('/member/coupon')">{{L['我的优惠券']}}</a> -->
                                <a
                                    href="javascript:void(0)"
                                    class="li_item_more_item"
                                    @click="goToByPush('/member/balance')"
                                    >{{ L["我的余额"] }}</a
                                >
                            </div>
                        </div>
                    </li>
                    <!-- <li @click="goToByPush('/service',{vid:0})">
            <div class="li_item">官方客服</div>
          </li> -->
                    <li @click="goToByPush('/article')">
                        <div class="li_item">{{ L["服务中心"] }}</div>
                    </li>
                    <li @click="store.state.loginFlag ? (scanCodeShow = true) : goToByPush('/login')">
                        <div class="li_item">{{ L["扫码关注"] }}</div>
                    </li>
                    <li>
                        <div class="li_item" style="cursor: auto">客服电话：0474-3639323</div>
                    </li>
                </ul>
            </div>
        </div>
        <el-dialog v-model="qiyecaigouShow" width="1200px" custom-class="qiyeDialog" :show-close="false" top="30px">
            <template #title>
                <div class="title"></div>
            </template>
            <div class="caigouBox">
                <div class="topBox">
                    <div>企业会员 <span class="rightSpan">专属权益</span></div>
                    <img class="icon" src="@/assets/qiyecaigou/qiyecaigouDialogIcon.png" alt="" />
                </div>
                <div class="topContent">
                    <div class="topVal" v-for="(val, i) in fourList" :key="i">
                        <img src="@/assets/qiyecaigou/qiyecaigouDialogChecked.png" alt="" />
                        {{ val }}
                    </div>
                </div>
                <div class="bottomContent">
                    <div class="bottomVal" v-for="(val, i) in eightList" :key="i">
                        <img :src="require(`@/assets/qiyecaigou/${i + 1}.png`)" alt="" />
                        <div class="name">{{ val.name }}</div>
                        <div class="label">{{ val.label }}</div>
                    </div>
                </div>
                <template v-if="!loginFlag">
                    <button class="btn" @click="router.push('/login')">立即开通</button>
                    <div class="login" @click="router.push('/login')">已有会员，立即登录</div>
                </template>
                <img class="close" src="@/assets/qiyecaigou/close.png" alt="" @click="qiyecaigouShow = false" />
            </div>
        </el-dialog>
        <el-dialog v-model="dialogFormVisible" title="企业切换" width="25%">
            <div class="dialogForm_box">
                <div v-for="(item, index) in priseList.data" :key="index" @click="isNow_radio(index)">
                    <div class="dialogForm_box_o">
                        <p>{{ item.corpName }}</p>
                        <img src="../assets/radio_checked.png" alt="" v-if="item.isNow == 1" />
                        <img
                            src="../assets/radio_unchecked.png"
                            alt=""
                            v-if="item.isNow == 0"
                            @click="isNow_radio(index)"
                        />
                    </div>
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="comfirm_rad">确认</el-button>
                </span>
            </template>
        </el-dialog>
        <div class="gongzhonghaodDialog">
            <el-dialog v-model="scanCodeShow" width="300px" center @close="scanCodeClose">
                <div
                    slot="title"
                    style="
                        padding-left: 10px;
                        margin-bottom: 20px;
                        color: #000;
                        height: 26px;
                        border-bottom: 1px solid #eee;
                    "
                    :style="{ textAlign: base64Img ? 'center' : '' }"
                >
                    {{ base64Img ? "关注公众号" : "请选择您的角色" }}
                </div>

                <div v-if="base64Img" style="text-align: center; color: #000; padding: 0 20px">
                    <img style="width: 200px" :src="base64Img" alt="" />
                    <div style="margin: 20px 0">扫码关注公众号，接收实时消息，专属二维码，请勿分享给他人</div>
                </div>
                <div v-else style="margin: 30px auto; color: #000; text-align: center">
                    <div style="margin-bottom: 10px">财务人员将接收到付款相关信息提醒</div>
                    <div>采购人员将接收到订单采购相关提醒</div>
                </div>
                <div slot="footer" style="padding: 0 20px; margin-bottom: 20px">
                    <div style="text-align: center">
                        <div v-if="base64Img">
                            <el-button style="background-color: #0052d9" @click="scanCodeShow = false">
                                关闭
                            </el-button>
                        </div>
                        <div v-else style="display: flex">
                            <el-button
                                style="width: 50%; color: #000; background-color: #d2d2d2"
                                @click="getBaseImg(2)"
                            >
                                财务人员
                            </el-button>
                            <el-button
                                style="width: 50%; color: #000; background-color: #d2d2d2"
                                @click="getBaseImg(3)"
                            >
                                采购人员
                            </el-button>
                        </div>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getCurrentInstance, onMounted, reactive, ref } from "vue";
import { platform } from "@/utils/config";
import { ElMessage } from "element-plus";
export default {
    name: "NavTopBar",
    setup() {
        const { proxy } = getCurrentInstance();
        const store = useStore();
        const L = proxy.$getCurLanguage();
        const memberInfo = store.state.memberInfo;
        const loginFlag = store.state.loginFlag;
        const router = useRouter();
        const dialogFormVisible = ref(false);
        const configInfo = reactive({ data: {} });
        const priseList = reactive({
            data: []
        });
        const scanCodeShow = ref(false);
        const qiyecaigouShow = ref(false);
        const vipPrice = ref(true);
        const base64Img = ref("");
        const fourList = reactive(["降本增效", "私有化部署", "全网比价", "超值特权"]);
        const eightList = reactive([
            { name: "企业专享价", label: "精选商品，企业专享低价" },
            { name: "支付方式", label: "支持多种支付方式" },
            { name: "采购管家", label: "提供企业采购全流程服务" },
            { name: "采购账单", label: "企业可视化数据统计" },
            { name: "主子账号", label: "多角色账号审批流程" },
            { name: "电子合同", label: "秒级签约，省时省力" },
            { name: "一对一售后", label: "一对一服务，售后无忧" },
            { name: "免运费", label: "批量采购，免运费" }
        ]);
        //去注册
        const goRegister = () => {
            router.push({
                path: `/register`,
                query: {
                    redirect: encodeURIComponent(window.location.href)
                }
            });
        };
        const scanCodeClose = () => {
            base64Img.value = "";
        };
        const getBaseImg = (type) => {
            console.log(store.state);
            proxy
                .$get("api/shopp/user/scene/code/temp", {
                    memberId: store.state.memberInfo.memberId,
                    type
                })
                .then((res) => {
                    base64Img.value = res.data;
                });
        };
        // 选择企业
        const isNow_radio = (ind) => {
            priseList.data.map((item) => {
                item.isNow = 0;
            });
            priseList.data[ind].isNow = 1;
        };
        // 切换确认
        const comfirm_rad = () => {
            let memberId = "";
            let items = {};
            priseList.data.map((item) => {
                if (item.isNow == 1) {
                    memberId = item.memberId;
                    items = item;
                }
            });
            if (items.enterpriseState == 4) {
                router.push({
                    path: "/registrationReview",
                    query: {
                        memberId: items.memberId,
                        reason: items.auditReason
                    }
                });
                return;
            } else if (items.enterpriseState == 2) {
                router.push({
                    path: "/registrationReview",
                    query: {
                        memberId: items.memberId,
                        type: 1
                    }
                });
                return;
            }
            proxy
                .$get("api/shopp/member/cutCorp", { memberId: memberId })
                .then((res) => {
                    if (res.code == 200) {
                        localStorage.setItem("access_token", res.data.accessToken);
                        localStorage.setItem("refresh_token", res.data.accessToken);
                        localStorage.setItem("time", new Date().getTime().toString()); //存储refresh_token更新时间
                        proxy
                            .$get("api/shopp/member/detail", { uscUserId: res.data.loginUser.uscUserId })
                            .then((ress) => {
                                if (ress.code == 200) {
                                    store.commit("updateMemberInfo", ress.data);
                                    proxy.$getLoginCartListData(); //更新购物车数据
                                    dialogFormVisible.value = false;
                                    router.push("/member/index");
                                    location.reload();
                                }
                            });
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };

        const getEnterprise = () => {
            // console.log(store.state.memberInfo);
            // priseList.data = [
            //     {
            //         memberId: store.state.memberInfo.corpId,
            //         enterpriseName: store.state.memberInfo.corpName,
            //         isNow: 1
            //     }
            // ];
            proxy
                .$get("api/shopp/member/getMyAllCorp")
                .then((res) => {
                    if (res.code == 200) {
                        dialogFormVisible.value = true;
                        priseList.data = res.data;
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };

        // 获取系统配置信息
        const getSystemConfigInfo = () => {
            proxy
                .$get("api/shopp/setting/getSettings", {
                    names: "main_site_logo,main_user_center_logo,main_user_logon_bg,main_user_register_bg,pc_home_bottom_adv,main_user_forget_password_bg,basic_site_name,basic_site_icp,basic_site_copyright,basic_site_technical_support,platform_customer_service_name,platform_customer_service_logo"
                })
                .then((res) => {
                    if (res.state == 200) {
                        configInfo.data = {
                            main_site_logo: res.data[0],
                            main_user_center_logo: res.data[1],
                            main_user_logon_bg: res.data[2],
                            main_user_register_bg: res.data[3],
                            pc_home_bottom_adv: res.data[4],
                            main_user_forget_password_bg: res.data[5],
                            basic_site_name: res.data[6],
                            basic_site_icp: res.data[7],
                            basic_site_copyright: res.data[8],
                            basic_site_technical_support: res.data[9],
                            platform_customer_service_name: res.data[10],
                            platform_customer_service_logo: res.data[11]
                        };
                        store.commit("updateConfigInfo", configInfo.data);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        getSystemConfigInfo();
        //前往具体的页面，type为具体的页面地址，param为参数对象
        const goToByPush = (type, param = {}) => {
            if (type == "/service") {
                let chatInfo = {
                    storeId: 0,
                    storeName: configInfo.data.platform_customer_service_name,
                    vendorAvatar: configInfo.data.platform_customer_service_logo,
                    showData: {},
                    source: ""
                };
                store.commit("saveChatBaseInfo", chatInfo);

                let newWin = router.resolve({
                    path: type,
                    query: param
                });

                window.open(newWin.href, "_blank");
                return;
            } else if (type == "/member/balance") {
                if (!memberInfo.isLookWallet) {
                    ElMessage.warning("暂无权限访问");
                    return;
                }
            }

            router.push({
                path: type,
                query: param
            });
        };
        //退出登录
        const loginOut = () => {
            store.commit("clearAllData"); //清除数据
            // proxy.$socket.disconnect()
            goToByPush("/login", {});
        };

        return {
            L,
            goRegister,
            isNow_radio,
            comfirm_rad,
            memberInfo,
            dialogFormVisible,
            loginOut,
            loginFlag,
            goToByPush,
            configInfo,
            priseList,
            getEnterprise,
            platform,
            scanCodeShow,
            base64Img,
            scanCodeClose,
            getBaseImg,
            store,
            router,
            //enterpriseProcurement,
            qiyecaigouShow,
            fourList,
            eightList,
            vipPrice
        };
    }
};
</script>
<style lang="scss" scoped>
.header_wrap {
    width: 100%;
    height: 33px;
    background: #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: center;

    .header {
        width: 1210px;
        height: 33px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header_left {
            height: 100%;
            font-size: 12px;
            color: #999;
            line-height: 36px;
            display: flex;
            align-items: center;

            .hello {
                margin-right: 20px;
                color: #999;
            }

            .h1 {
                margin: 0 5px;
                cursor: pointer;

                &:hover {
                    color: $colorMain;
                }
            }
        }

        .header_right {
            height: 100%;

            ul {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;

                .personal_center {
                    width: 121px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                li {
                    float: left;
                    text-align: center;
                    border-right: 1px solid #ddd;
                    padding: 0 10px;
                    height: 12px;

                    .li_item {
                        position: relative;
                        cursor: pointer;
                        font-size: 12px;
                        line-height: 12px;
                        color: #999;
                        height: 33px;

                        &:hover {
                            color: $colorMain;

                            &.has_more {
                                &:before {
                                    border-top-color: $colorMain;
                                }
                            }

                            .li_item_more {
                                display: block;
                            }
                        }

                        &.has_more {
                            padding-right: 12px;

                            &:before,
                            &:after {
                                position: absolute;
                                right: -2px;
                                display: block;
                                width: 0;
                                height: 0;
                                content: " ";
                                border: 4px solid transparent;
                                border-radius: 2px;
                            }

                            &:before {
                                top: 3px;
                                border-top: 5px solid #888;
                            }

                            &:after {
                                top: 1px;
                                border-top: 5px solid #f7f7f7;
                            }
                        }

                        .li_item_more {
                            position: absolute;
                            width: 80px;
                            padding: 5px 3px;
                            left: 50%;
                            transform: translateX(-50%);
                            top: 21px;
                            background: #fff;
                            box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
                            z-index: 999;
                            display: none;

                            &:before,
                            &:after {
                                position: absolute;
                                top: -11px;
                                left: 50%;
                                transform: translateX(-50%);
                                -webkit-transform: translateX(-50%);
                                display: block;
                                content: " ";
                                width: 0;
                                height: 0;
                                border: 5px solid transparent;
                                border-bottom: 6px solid #dedede;
                            }

                            &:after {
                                top: -10px;
                                border-bottom: 6px solid #fff;
                            }

                            .li_item_more_item {
                                display: block;
                                text-align: center;
                                color: #999;
                                line-height: 12px;
                                padding: 8px 0;

                                &:hover {
                                    color: $colorMain;
                                }
                            }
                        }
                    }

                    &:last-child {
                        border-right-width: 0;
                        padding-right: 0;
                    }
                }
            }
        }
    }
    .dialogForm_box {
        .dialogForm_box_o {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 55px;
            border-bottom: 1px solid #bbb;
            img {
                width: 22px;
                height: 22px;
            }
        }
    }
    .el-button {
        background-color: $colorMain;
        color: #ffffff;
    }
}
</style>
<style lang="scss">
.header_wrap {
    .el-dialog__body {
        /*padding: 10px 0px !important;*/
    }
    .el-dialog__headerbtn {
        top: 8px;
    }
}
.qiyeDialog {
    padding: 0;
    top: calc(50% - 60px) !important;
    transform: translate(0, -50%) !important;
    border-radius: 20px !important;
    .el-dialog__header,
    .el-dialog__body,
    .title {
        border-radius: 20px !important;
        padding: 0;
        display: flex;
    }
}
.caigouBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1200px;
    background-image: url(../assets/qiyecaigou/qiyecaigouDialogBg0.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
    .topBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 42px 0 54px;
        width: 1200px;
        margin-top: 10px;
        font-weight: 400;
        font-size: 54px;
        color: #f8f8f8;
        font-style: italic;
        .rightSpan {
            color: #feeed2;
        }
        .icon {
            width: 149px;
            height: 145px;
        }
    }
    .topContent,
    .bottomContent {
        padding: 60px 189px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .topVal {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 144px;
            height: 54px;
            background: linear-gradient(-90deg, #eac286, #fae7c2);
            border-radius: 6px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 21px;
            color: #663409;
            img {
                position: absolute;
                top: -10px;
                width: 23px;
                height: 23px;
            }
        }
    }
    .bottomContent {
        padding: 60px 58px;
        .bottomVal {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                width: 70px;
                height: 70px;
            }
            .name {
                margin: 23px 0 14px;
                font-weight: 400;
                font-size: 19px;
                color: #663409;
            }
            .label {
                font-weight: 400;
                font-size: 13px;
                color: #b39173;
            }
        }
    }
    .btn {
        width: 262px;
        height: 57px;
        background: linear-gradient(90deg, #365796, #0f3379);
        border-radius: 29px;
        font-size: 27px;
        color: #fdf6eb;
        cursor: pointer;
    }
    .login {
        margin: 17px 0 20px;
        font-weight: 400;
        font-size: 19px;
        color: #663409;
        text-align: center;
        cursor: pointer;
    }
    .close {
        position: absolute;
        bottom: -60px;
        width: 46px;
        height: 46px;
        cursor: pointer;
    }
}
.vipPrice {
    margin-left: 10px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    height: 22px;
    background: #0e3177;
    border-radius: 11px;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    img {
        width: 13px;
        height: 13px;
    }
}
.gongzhonghaodDialog {
    .el-dialog__header {
        padding: 0;
        margin-top: 12px;
    }
}
</style>
